/* FORMS */
label {
    margin: 0;
}
label:has(> input) {
    display: inline-grid;
}
.modal {
	z-index: var(--z-600) !important;
}
/* Botão */
.pb23-btn.close {
    position: relative;
    opacity: 1;
    float: none;
}
/* Old Select (Forms) */
div[class^="pb-"] label:before {
    display: none;
}
div[class^="pb-"] label select {
    min-height: unset;
    -webkit-appearance: auto;
    -moz-appearance: auto;
    -o-appearance: auto;
    appearance: auto;
}

.pb23-tabs--carousel-archtrends .pb23-carousel--navigation {
    --carousel-nav--spacer: 10px;
    margin-top: 20px;
}
@media (max-width: 769px) {    
    .pb23-tabs--carousel-archtrends .pb23-carousel--navigation {
        --carousel-nav--spacer: 0px;
    }
}

/* Páginas Específicas (Pages) */ 
body#Index_index .pb23-carousel--grid-banners
{
    margin-top: 60px;
}

#Product_searchSimple {
    min-height: calc(100dvh - var(--menu-height));
}
/*
#search {
    min-height: calc(100dvh - 470px - var(--menu-height));
}
#search:has(.list.search-notfound) {
    height: calc(100dvh - 470px - var(--menu-height));
}
#search .list.search-notfound {
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
#search:has(.list.search-notfound):not(:has(~ .pb23-footer .newsletter)) {
    height: calc(100dvh - 268px - var(--menu-height));
}
@media (max-width:769px){
    #search .list.search-notfound {
        padding-top: 48px;
    }
}
@media (max-height: 900px) and (max-width: 769px) {
    #search:has(.list.search-notfound) {
        height: auto;
    }
}

.pb23-form.formNewsletter label span {
    padding-top: 0px;
}*/